<template>
    <!--编辑页与详情页相同-->
    <div class="SupplierDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-form-item label="编码">
                        <el-input readonly v-model="form.code" />
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input readonly v-model="form.name" />
                    </el-form-item>
                    <el-form-item label="联系人">
                        <el-input readonly v-model="form.linkman" />
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input readonly v-model="form.mobile" />
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-input readonly v-model="form.address" />
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'SupplierDetail',
    props: ['form'],
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
